import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../../SessionContext.tsx";
import { ProgressiveRequest } from "../../ProgressiveRequest.tsx";
import { getServer } from '../../Server.tsx';
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconX from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/x.tsx";
import IconAntennaBars4 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/antenna-bars-4.tsx";

export class MatchingContentLinkingView extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		item: this.props.item,
		accounts: null,
		mod: 'view'
	};

	conf = {
		title: '',
		type: this.props.type
	};

	componentDidMount() {
		let that = this;
		
		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/matching',
			method: 'post',
			data: {
				action: 'accountsForLinking',
				service: that.conf.type.service.id,
				type: that.conf.type.genericType,
				session: session
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);

				that.setState({accounts: data.list});

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	save(){
		let that = this;
		let session = this.context;

		that.setState({mod: 'view'});

		var form = {
			id: that.state.item.id,
			accountingItem: this.state.item.accountingItem
		};

		console.log(form);
		
		ProgressiveRequest({
			url: getServer()+'/api/front/matching',
			method: 'post',
			data: {
				action: 'saveAccountingItem',
				session: session,
				form: form
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});

	}

	render() {
		let that = this;

		if(that.state.mod == 'edit'){
			
			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={()=>{that.setState({mod: 'view'});}}>
							<IconX class="w-6 h-6" />
						</div>
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconAntennaBars4 class="w-6 h-6" /><span class="ms-2">{this.conf.type.name} ({this.conf.type.service.name})</span>
						</h1>
					</div>
					<div class="my-4">
						<div class="card my-2">
							<div class="card-body py-3">
								<div><h2 class="d-flex align-items-center">{this.state.item.name}</h2></div>
								<div>{this.state.item.detail}</div>
								<div class="mt-2">Compte comptable : 
									<select className="form-select" defaultValue={this.state.item.accountingItem} onChange={function(ev){that.setState({item: {...that.state.item, ...{accountingItem: ev.target.value}}});}}>
										{that.state.accounts ? 
											that.state.accounts.map(function(elt){
												return <option value={elt.id}>[{elt.nature}] {elt.name} ({elt.accountNum})</option>;
											})
											:null}
									</select>
								</div>
							</div>
						</div>
						<div class="d-flex align-items-center justify-content-between">
							<h2 class="mt-3 d-flex align-items-center"></h2>
							<button type="button" class="btn btn-primary rounded-pill d-flex align-items-center btn-sm px-3" onClick={()=>{that.save();}}>valider</button>
						</div>
					</div>
				</div>
			);

		}else{

			let label = '-';

			if(this.state.item.accountingItem){
				for(let account of that.state.accounts){
					if(account.id == this.state.item.accountingItem){
						label = `[${account.nature}] ${account.name} (${account.accountNum})`;
					}
				}
			}

			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
							<IconChevronLeft class="w-6 h-6" />
						</div>
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconAntennaBars4 class="w-6 h-6" /><span class="ms-2">{this.conf.type.name} ({this.conf.type.service.name})</span>
						</h1>
					</div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between">
							<h2 class="mt-3 d-flex align-items-center"></h2>
							<button type="button" class="btn btn-light bg-white rounded-pill d-flex align-items-center btn-sm px-3" onClick={()=>{that.setState({mod: 'edit'});}}>éditer</button>
						</div>
						<div class="card my-2">
							<div class="card-body py-3">
								<div><h2 class="d-flex align-items-center">{this.state.item.name}</h2></div>
								<div>{this.state.item.detail}</div>
								<div>Compte comptable : {label}</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		
	}
}
