import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../../SessionContext.tsx";
import { MatchingContentLinkingList } from "./List.tsx";
import { ProgressiveRequest } from "../../ProgressiveRequest.tsx";
import { getServer } from '../../Server.tsx';
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import IconAntennaBars4 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/antenna-bars-4.tsx";
import IconBook2 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/book-2.tsx";

export class MatchingContentLinking extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		info: {},
		selectedType: null
	};

	_state = {
		loading: true,
		info: {},
		selectedType: null
	};

	customSetState(o){
		this.setState(o);
		let nwState = {};
		for(let prop in this._state){
			nwState[prop] = this._state[prop];
		}
		for(let prop in o){
			nwState[prop] = o[prop];
		}
		this._state = nwState;
	}
	
	conf = {
		service: this.props.service,
		title: '',
		description: null,
		genericItemTypes: []
	};

	componentDidMount() {
		let that = this;

		switch(this.conf.service){
			case 'fdj':
				const fdj = {
					id: 3,
					code: 'fdj',
					name: 'FDJ'
				};
				this.conf.title = fdj.name;
				this.conf.genericItemTypes = [
					{
						name: 'Les jeux',
						mandatory: false,
						genericType: 'product',
						category: 'ventes',
						service: fdj
					}
				];
			break;
			case 'aleda':
				const aleda = {
					id: 1,
					code: 'aleda',
					name: 'Aleda'
				};
				this.conf.title = aleda.name;
				this.conf.description = `Les rubriques obligatoires sont nécessaires pour ventiler les chiffres dans les comptes comptables adéquats. Les rubriques non obligatoires permettent de modifier la ventilation pour certaines occurrences. Par exemple, si vous souhaitez affecter le CBD dans le compte comptable "autre tabac" alors qu'il est affecté au compte comptable "produits pipiers" liés à sa famille "détail pipier", vous pouvez alors faire la ventilation manuellement de ce produit dans "autre tabac".`;
				this.conf.genericItemTypes = [
					{
						name: 'Les familles',
						mandatory: true,
						genericType: 'category',
						category: 'ventes',
						service: aleda
					},
					{
						name: 'Les TVA',
						mandatory: true,
						genericType: 'tax',
						category: 'taxes',
						service: aleda
					},
					{
						name: 'Les moyens de paiement',
						mandatory: true,
						genericType: 'payment_type',
						category: 'paiements',
						service: aleda
					},
					{
						name: 'Les produits',
						mandatory: false,
						genericType: 'product',
						category: 'ventes',
						service: aleda
					}
				];
			break;
			case 'laddition':
				const laddition = {
					id: 2,
					code: 'laddition',
					name: 'L\'Addition'
				};
				this.conf.title = laddition.name;
				this.conf.description = `Les rubriques obligatoires sont nécessaires pour ventiler les chiffres dans les comptes comptables adéquats. Les rubriques non obligatoires permettent de modifier la ventilation pour certaines occurrences. Par exemple, si vous souhaitez affecter le café dans le compte comptable "solides" alors qu'il est affecté au compte comptable "liquides" liés à son type de produit "café", vous pouvez alors faire la ventilation manuellement de ce produit dans "solides".`;
				this.conf.genericItemTypes = [
					{
						name: 'Les types de produits',
						mandatory: true,
						genericType: 'product_type',
						category: 'ventes',
						service: laddition
					},
					{
						name: 'Les TVA',
						mandatory: true,
						genericType: 'tax',
						category: 'taxes',
						service: laddition
					},
					{
						name: 'Les moyens de paiement',
						mandatory: true,
						genericType: 'payment_type',
						category: 'paiements',
						service: laddition
					},
					{
						name: 'Les produits',
						mandatory: false,
						genericType: 'product',
						category: 'ventes',
						service: laddition
					}
				];
			break;
		}
		
		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		let types = [];
		for(let item of this.conf.genericItemTypes){
			types.push(item.genericType);
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/matching',
			method: 'post',
			data: {
				action: 'linkingContentCount',
				types: types,
				service: this.conf.service,
				session: session
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);

				if(data.init){
					that.customSetState({
						info: data.info
					});
				}else{
					let nwItems = {};
					for(let prop in that._state.info){
						nwItems[prop] = that._state.info[prop];
					}
					for(let prop in data.info){
						nwItems[prop] = data.info[prop];
					}
					that.customSetState({
						info: nwItems
					});
				}

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	select = function(item){
		this.setState({selectedType: item});
	}
	
	render() {
		let that = this;

		if(that.state.selectedType){
			
			return (
				<div>
					<MatchingContentLinkingList type={that.state.selectedType} service={that.conf.service} back={()=>{this.setState({selectedType: null});}} />
				</div>
			);

		}else{

			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
							<IconChevronLeft class="w-6 h-6" />
						</div>
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconAntennaBars4 class="w-6 h-6" /><span class="ms-2">Les liens avec les applications</span>
						</h1>
					</div>
					<div class="my-4">
						<h2 class="mt-3 d-flex align-items-center"><IconBook2 class="w-6 h-6 me-2" />{this.conf.title}</h2>
						{this.conf.description?
							<div class="card my-2">
								<div class="card-body">
								{this.conf.description}
								</div>
							</div>
							:null}
						<div class="">
							{this.conf.genericItemTypes.map((type)=>{
								return (
									<div class="card my-2" onClick={()=>{that.select(type);}}>
										<div class="card-body">
											<div class="d-flex align-items-center justify-content-between">
												<div>
													<h2 class="d-flex align-items-center h4 mb-0">
														<span class="">{type.name}</span>
													</h2>
													<div>
													{that._state.info.links && that._state.info.links.data[type.genericType]?
														<>
															<span class="badge rounded-pill bg-light text-dark me-2">{that._state.info.links.data[type.genericType].nb} éléments</span>
															{that._state.info.links.data[type.genericType].missing?
																<span class={"badge rounded-pill me-2 "+(type.mandatory > 0?'bg-danger':'bg-warning')}>{that._state.info.links.data[type.genericType].missing} manquants</span>
																:null
															}
														</>
														:null
													}
													<span class="badge rounded-pill bg-light text-dark me-2">{type.category}</span>
														{type.mandatory > 0?
															<span class="me-2 badge rounded-pill bg-secondary">obligatoire</span>
															:null}
													</div>
												</div>
												<div class="p-2 rounded-pill bg-light">
													<IconChevronRight class="w-6 h-6" />
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			);
		}
		
	}
}
