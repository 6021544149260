import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { MatchingContentAccounting } from "./Accounting/Content.tsx";
import { MatchingContentLinking } from "./Linking/Content.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import IconAntennaBars4 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/antenna-bars-4.tsx";
import IconBook2 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/book-2.tsx"
import IconLayersLinked from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/layers-linked.tsx";

export function MatchingContent({ back, args }){

	return (
		<div>
			<_MatchingContent back={back} args={args}/>
		</div>
	);
}

class _MatchingContent extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		info: {},
		selectedItem: null
	};

	_state = {
		loading: true,
		info: {},
		selectedItem: null
	};
	
	conf = {
		accounts: [
			{
				identifier: 'accounting.sell',
				refNb: 'nbSellAccounts',
				label: 'Les ventes'
			},
			{
				identifier: 'accounting.tax',
				refNb: 'nbTaxAccounts',
				label: 'Les taxes'
			},
			{
				identifier: 'accounting.payment',
				refNb: 'nbPayAccounts',
				label: 'Les paiements'
			},
			{
				identifier: 'accounting.others',
				refNb: 'nbOthers',
				label: 'Divers'
			}
		]
	};

	customSetState(o){
		this.setState(o);
		let nwState = {};
		for(let prop in this._state){
			nwState[prop] = this._state[prop];
		}
		for(let prop in o){
			nwState[prop] = o[prop];
		}
		this._state = nwState;
	}
	
	componentDidMount() {
		let that = this;

		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/matching',
			method: 'post',
			data: {
				action: 'content',
				session: session
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);

				if(data.init){
					that.customSetState({
						info: data.info
					});
				}else{
					let nwItems = {};
					for(let prop in that._state.info){
						nwItems[prop] = that._state.info[prop];
					}
					for(let prop in data.info){
						nwItems[prop] = data.info[prop];
					}
					that.customSetState({
						info: nwItems
					});
				}

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	select = function(item){
		this.setState({selectedItem: item});
	}

	unselect = function(){
		this.setState({selectedItem: null});
	}
	
	render() {
		let that = this;

		if(that.state.selectedItem){

			switch(that.state.selectedItem){
				case 'accounting.sell':

					return <MatchingContentAccounting back={()=>{that.unselect();}} type="sell" />;
				break;
				case 'accounting.tax':

					return <MatchingContentAccounting back={()=>{that.unselect();}} type="tax" />;
				break;
				case 'accounting.payment':

					return <MatchingContentAccounting back={()=>{that.unselect();}} type="payment" />;
				break;
				case 'accounting.others':

					return <MatchingContentAccounting back={()=>{that.unselect();}} type="others" />;
				break;
				case 'links.fdj':

					return <MatchingContentLinking back={()=>{that.unselect();}} service="fdj" />;
				break;
				case 'links.aleda':

					return <MatchingContentLinking back={()=>{that.unselect();}} service="aleda" />;
				break;
				case 'links.laddition':

					return <MatchingContentLinking back={()=>{that.unselect();}} service="laddition" />;
				break;
			}

		}else{

			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						{that.props.back?
							<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
								<IconChevronLeft class="w-6 h-6" />
							</div>:
							<a class="p-1 rounded-pill text-white me-4 arrowb" href="/">
								<IconChevronLeft class="w-6 h-6" />
							</a>
						}
						<h1 class="d-flex align-items-center h3 mb-0 titleb">
							<IconAntennaBars4 class="w-6 h-6" /><span class="ms-2">Le référentiel</span>
						</h1>
					</div>
					<div class="my-4">
						<h2 class="mt-3 d-flex align-items-center"><IconBook2 class="w-6 h-6 me-2" />Les comptes comptables</h2>
						{that.conf.accounts.map((item)=>{
							return <div class="card my-2" onClick={()=>{that.select(item.identifier);}}>
								<div class="card-body">
									<div class="d-flex align-items-center justify-content-between">
										<div>
											<h2 class="my-2 h5"><span class="ms-2">{item.label}</span></h2>
											<div>{this._state.info.accounting && this._state.info.accounting.data && this._state.info.accounting.data[item.refNb] !== null?<span class="bg-light px-2 py-1 rounded-2">{this._state.info.accounting.data[item.refNb]} élément{this._state.info.accounting.data[item.refNb] > 1?'s':''}</span>:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>}</div>
										</div>
										<IconChevronRight class="w-6 h-6" />
									</div>
								</div>
							</div>;
						})}
						<h2 class="mt-3 d-flex align-items-center"><IconLayersLinked class="w-6 h-6 me-2" />Les liens avec les applications</h2>
						<div class="card my-2" onClick={()=>{that.select('links.fdj');}}>
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between">
									<div>
										<h2 class="h5 my-2"><span class="ms-2">FDJ</span></h2>
										<div>
											{
												this._state.info.links && this._state.info.links.data && this._state.info.links.data.fdj?
													<>
														<span class="bg-light px-2 py-1 rounded-2">{this._state.info.links.data.fdj.nb} lien{this._state.info.links.data.fdj.nb>1?'s':''}</span>
														{this._state.info.links.data.fdj.missing?<span class="px-2 py-1 bg-danger text-light rounded-2 ms-2">{this._state.info.links.data.fdj.missing} manquants</span>:null}
													</>
													:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>
											}
										</div>
									</div>
									<IconChevronRight class="w-6 h-6" />
								</div>
							</div>
						</div>
						<div class="card my-2" onClick={()=>{that.select('links.aleda');}}>
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between">
									<div>
										<h2 class="h5 my-2"><span class="ms-2">Aleda</span></h2>
										<div>
											{
												this._state.info.links && this._state.info.links.data && this._state.info.links.data.aleda?
													<>
														<span class="bg-light px-2 py-1 rounded-2">{this._state.info.links.data.aleda.nb} lien{this._state.info.links.data.aleda.nb>1?'s':''}</span>
														{this._state.info.links.data.aleda.missing?<span class="px-2 py-1 bg-danger text-light rounded-2 ms-2">{this._state.info.links.data.aleda.missing} manquants</span>:null}
													</>
													:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>
											}
										</div>
									</div>
									<IconChevronRight class="w-6 h-6" />
								</div>
							</div>
						</div>
						<div class="card my-2" onClick={()=>{that.select('links.laddition');}}>
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between">
									<div>
										<h2 class="h5 my-2"><span class="ms-2">L'Addition</span></h2>
										<div>
											{
												this._state.info.links && this._state.info.links.data && this._state.info.links.data.laddition?
													<>
														<span class="bg-light px-2 py-1 rounded-2">{this._state.info.links.data.laddition.nb} lien{this._state.info.links.data.laddition.nb>1?'s':''}</span>
														{this._state.info.links.data.laddition.missing?<span class="px-2 py-1 bg-danger text-light rounded-2 ms-2">{this._state.info.links.data.laddition.missing} manquants</span>:null}
													</>
													:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>
											}
										</div>
									</div>
									<IconChevronRight class="w-6 h-6" />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		
	}
}
