import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../../SessionContext.tsx";
import { MatchingContentLinkingView } from "./View.tsx";
import { MatchingContentLinkingListItem } from "./ListItem.tsx";
import { ProgressiveRequest } from "../../ProgressiveRequest.tsx";
import { getServer } from '../../Server.tsx';
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconAntennaBars4 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/antenna-bars-4.tsx";
import IconBook2 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/book-2.tsx";

export class MatchingContentLinkingList extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		list: null,
		selectedItem: null
	};

	conf = {
		service: this.props.service,
		type: this.props.type
	};

	componentDidMount() {
		let that = this;
		
		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/matching',
			method: 'post',
			data: {
				action: 'linkingContentList',
				service: this.conf.service,
				type: this.conf.type.genericType,
				session: session
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);

				that.setState({list: data.list});

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	select = function(item){
		this.setState({selectedItem: item});
	}
	
	render() {
		let that = this;

		if(that.state.selectedItem){
			
			return (
				<div>
					<MatchingContentLinkingView item={that.state.selectedItem} type={that.props.type} back={()=>{this.setState({selectedItem: null});}} />
				</div>
			);

		}else{
			
			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
							<IconChevronLeft class="w-6 h-6" />
						</div>
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconAntennaBars4 class="w-6 h-6" /><span class="ms-2">{this.conf.type.service.name}</span>
						</h1>
					</div>
					<div class="my-4">
						<h2 class="mt-3 d-flex align-items-center"><IconBook2 class="w-6 h-6 me-2" />{this.conf.type.name}</h2>
						<div class="card my-2">
							<div class="card-body">
								<div class="list-group border-0">
									{this.state.list && this.state.list.map((item)=>{
										return <MatchingContentLinkingListItem item={{id: item.id, name: item.name, detail: item.ref, missing: !item.accountingItem}} type={that.conf.type} select={(it)=>{that.select(it);}} />;
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
