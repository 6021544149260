import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../../SessionContext.tsx";
import { ProgressiveRequest } from "../../ProgressiveRequest.tsx";
import { getServer } from '../../Server.tsx';
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";

export function MatchingContentLinkingListItem({ item, select }){
	
	return (
		<div class="list-group-item border-0 rounded-1 list-group-item-action py-2" onClick={()=>{select(item);}}>
			<div class="d-flex align-items-center justify-content-between">
				<div>
					<h2 class="d-flex align-items-center h4 mb-0">
						<span class="">{item.name}</span>
					</h2>
					<div>
						<span class="me-2">{item.detail}</span>
						{item.missing?
							<span class="badge rounded-pill bg-danger me-2">à renseigner</span>
							:null}
					</div>
				</div>
				<div class="p-2 rounded-pill bg-light">
					<IconChevronRight class="w-6 h-6" />
				</div>
			</div>
		</div>
	);
}
