import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../../SessionContext.tsx";
import { ProgressiveRequest } from "../../ProgressiveRequest.tsx";
import { getServer } from '../../Server.tsx';
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconX from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/x.tsx";
import IconAntennaBars4 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/antenna-bars-4.tsx";

export class MatchingContentAccountingView extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		item: this.props.item,
		mod: 'view'
	};

	conf = {
		type: this.props.type,
		title: '',
		prefix: ''
	};

	componentDidMount() {
		let that = this;

		switch(this.conf.type){
			case 'sell':
				this.conf.title = 'Les comptes de vente';
				this.conf.prefix = '706';
			break;
			case 'tax':
				this.conf.title = 'Les comptes de TVA';
				this.conf.prefix = '455';
			break;
			case 'payment':
				this.conf.title = 'Les comptes clients (paiements)';
				this.conf.prefix = '411';
			break;
			case 'others':
				this.conf.title = 'Les autres comptes';
				this.conf.prefix = '411';
			break;
		}
		
		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/matching',
			method: 'post',
			data: {
				action: 'content',
				session: session
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);

				that.setState({list: data.list});

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	save(){
		let that = this;
		let session = this.context;

		that.setState({mod: 'view'});

		var form = {
			id: that.state.item.id,
			name: that.state.item.name,
			accountNum: that.state.item.accountNum,
			accountLabel: that.state.item.accountLabel
		};
		
		ProgressiveRequest({
			url: getServer()+'/api/front/matching',
			method: 'post',
			data: {
				action: 'saveAccounting',
				session: session,
				form: form
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);
				
			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});

	}

	render() {
		let that = this;

		if(that.state.mod == 'edit'){
			
			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={()=>{that.setState({mod: 'view'});}}>
							<IconX class="w-6 h-6" />
						</div>
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconAntennaBars4 class="w-6 h-6" /><span class="ms-2">{this.conf.title}</span>
						</h1>
					</div>
					<div class="my-4">
						<div class="card my-2">
							<div class="card-body">
								<div class="mt-2">Nom : <input type="text" class="form-control" value={this.state.item.name} onChange={(ev)=>{that.setState({item: {...that.state.item, ...{name: ev.target.value}}});}} /></div>
								<div class="mt-2">Compte comptable : <input type="text" class="form-control" value={this.state.item.accountNum} onChange={(ev)=>{that.setState({item: {...that.state.item, ...{accountNum: ev.target.value}}});}} /></div>
								<div class="mt-2">Libellé sur export comptable : <input type="text" class="form-control" value={this.state.item.accountLabel} onChange={(ev)=>{that.setState({item: {...that.state.item, ...{accountLabel: ev.target.value}}});}} /></div>
							</div>
						</div>
						<div class="d-flex align-items-center justify-content-between">
							<h2 class="mt-3 d-flex align-items-center"></h2>
							<button type="button" class="btn btn-primary rounded-pill d-flex align-items-center btn-sm px-3" onClick={()=>{that.save();}}>valider</button>
						</div>
					</div>
				</div>
			);

		}else{

			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
							<IconChevronLeft class="w-6 h-6" />
						</div>
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconAntennaBars4 class="w-6 h-6" /><span class="ms-2">{this.conf.title}</span>
						</h1>
					</div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between">
							<h2 class="mt-3 d-flex align-items-center"></h2>
							<button type="button" class="btn btn-light bg-white rounded-pill d-flex align-items-center btn-sm px-3" onClick={()=>{that.setState({mod: 'edit'});}}>éditer</button>
						</div>
						<div class="card my-2">
							<div class="card-body">
								<div><h2 class="mt-3 d-flex align-items-center">{this.state.item.name}</h2></div>
								<div>Compte comptable : {this.state.item.accountNum}</div>
								<div>Libellé sur export comptable : {this.state.item.accountLabel}</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		
	}
}
